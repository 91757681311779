import { MaybeRefOrGetter } from "vue";

/**
 *
 * @param target - in addition to refs and html elements, this also accepts an element's id.
 * @param options - optional
 * @param {number} options.offset - number
 * @param options.offsetElement - in addition to refs and html elements, this also accepts an element's id.
 */
export const scrollToTarget = (
  target:
    | MaybeRefOrGetter<HTMLElement | SVGElement | null | undefined>
    | string,
  options: {
    offset?: number;
    offsetElement?:
      | MaybeRefOrGetter<HTMLElement | SVGElement | null | undefined>
      | string;
  } = {}
) => {
  let targetElement: HTMLElement | SVGElement | null | undefined;

  const _target = toValue(target);

  typeof _target == "string"
    ? (targetElement = document.getElementById(_target))
    : _target;

  // Getting the top of the target element, or logging console error
  let targetElemTop: number;
  try {
    targetElemTop = targetElement!.getBoundingClientRect().top;
  } catch {
    console.error("invalid scroll to target");
    return;
  }

  let finalOffset: number = 0;
  if (options.offsetElement) {
    let offsetTargetElement: HTMLElement | SVGElement | null | undefined;
    const _offsetTarget = toValue(options.offsetElement);

    typeof _offsetTarget == "string"
      ? (offsetTargetElement = document.getElementById(_offsetTarget))
      : _offsetTarget;

    finalOffset += offsetTargetElement
      ? offsetTargetElement.getBoundingClientRect().bottom
      : 0;
  }
  options.offset ? (finalOffset += options.offset) : null;

  const displacement = targetElemTop - finalOffset;
  if (window) {
    window.scrollBy({
      top: displacement,
      behavior: "smooth",
    });
  }
};
